// USA
export const locale = {
  ok: 'OK',
  cancel: 'Cancel',
  register: 'Register',
  login: 'Login',
  login_with_google: 'Login with Google',
  login_with_facebook: 'Login with Facebook',
  welcome: 'Welcome Back',
  logout: 'Logout',
  submit: 'Submit',
  contact_us: 'CONTACT US',
  become_an_organizer: 'START TICKET SALES NOW',
  support: 'Support',
  support_description: 'We will clarify any question about eventbox as quickly as possible. We will be happy to explain how you protect your privacy or what you have to do if your account has been hacked. If you have any questions or are unclear, please contact the organizer directly.',
  companis_: 'COMPANIES:',
  about_us: 'About US',
  news: 'News',
  contact: 'Contact',
  jobs: 'Jobs',
  programs_: 'PROGRAMS:',
  sponsorship: 'Sponsorship',
  support_: 'SUPPORT:',
  help_center: 'Help center',
  security_center: 'Security center',
  community_guidelines: 'Community guidelines',
  legal_: 'LEGAL:',
  cookie_policy: 'Cookie Policy',
  our_cookie_policy: 'Our Cookie Policy',
  imprint: 'Imprint',
  terms_service: 'Terms of Service',
  privacy_policy: 'Privacy Policy',
  business_user_agreement: 'Business User Agreement',
  about_header: 'Share your Moments & makes the World better!',
  our_vision: 'Our vision',
  about_description: 'Our vision is that people all over the world can capture the beautiful and unforgettable event experiences on eventbox and fascinate other people!\n\n' +
    'Do you want to be inspired too? Then download the eventbox app and be a part of us.',
  contact_partner: 'Werde Partner',
  contact_inquiries: 'Presseanfragen',
  contact_privacy: 'Datenschutz',
  jobs_title: 'Challenge yourself. Join eventbox.',
  jobs_header: 'Karriere bei eventbox\nWachse in einem starken Team',
  jobs_looking_for: 'Derzeit suchen wir:',
  advertise_on_eventbox: 'Advertise on eventbox',
  sponsorship_description: 'Benefit from many opportunities to advertise on eventbox. Present your product to a large number of potential customers worldwide.\n\n' +
    'For inquiries please contact our <a href="mailto:info@eventboxde.com"><u class="color-pink">Ads-Abteilung hier</u></a>',
  cookie_description: 'Diese Webseite benutzt Cookies. Wenn du diese Webseite weiter nutzt, gehen wir von deinem Einverständnis aus.',
  see_all_events: 'See all Events',
  no_data_found: 'No data found!',
  please_enter: 'Please enter ',
  email: 'Email',
  repeat_email: 'Repeat Email',
  phone: 'Phone',
  username_or_email: 'UserName or Email',
  password: 'Password',
  forgot_password: 'Forgot password?',
	do_not_have_an_account: 'Don\'t have an account?',
  select_account_type: 'Select account type',
  normal_user: 'Normal User',
  business_user: 'Business User',
  repeat_password: 'Repeat Password',
  username: 'Username',
  gender: 'Gender',
  undefined: 'Undefined',
  male: 'Male',
  female: 'Female',
  birthday: 'Birthday',
  organizer_name: 'Organizer Name',
  tax_code: 'Tax Code',
  address: 'Address',
  account_holder_name: 'Account Holder Name',
  iban_number: 'IBAN-Number',
  bic: 'BIC',
  description: 'Description',
  reset_password: 'Reset Password',
  next: 'Next',
  resend_code_in: 'Resend code in {0}:{1}',
  country_code: 'Country Code',
  example: 'Exemple:',
  save: 'Save',
  profile: 'Profile',
  my_events: 'My Events',
  my_tickets: 'My Tickets',
  category: 'Category',
  price: 'Price',
  piece: 'Piece',
  amount: 'Amount',
  group_ticket: 'Group Ticket',
  minimum: 'Min',
  maximum: 'Max',
  vip_ticket: 'Guestlist',
  vip_password: 'VIP Password',
  service_charge: 'Service Charge',
  total: 'Total',
  buy: 'Buy',
  ticket: 'Ticket',
  refunded: 'Refunded',
  live_event: 'Live Event',
  past_event: 'Past Event',
  payout_amount: 'Payout Amount',
  not_paid: 'Not Paid',
  already_paid: 'Already Paid',
  pending: 'Pending',
  sales_amount: 'Sales Amount',
  handling_fee: 'Handling Fee',
  refund_fee: 'Refund Fee',
  sold_tickets: 'Sold Tickets',
  check_ins: 'Check-Ins',
  sponsoring_quote: 'Your Question',
  support_email_sent: 'Email sent successfully.',
	support_email_sent_failed: 'Failed to send email.',
  add_event: 'Add Event',
  edit_event: 'Edit Event',
  event_name: 'Event name',
  start_date: 'Start date',
  end_date: 'End date',
  genre: 'Genre',
  add: 'Add',
  select_event_genre: 'Select event genre',
  yes: 'Yes',
  no: 'No',
  dashboard: 'Dashboard',
  event_settings: 'Event settings',
  sold_out: 'Sold Out',
  hide_ticket: 'Hide Ticket',
  hide_extra: 'Hide Extra',
  promo_code: 'Promo Code',
  code: 'Code',
  discount: 'Discount',
  now: 'Now',
  when_ticket_sales_end: 'When ticket sales end',
  unlimited: 'Unlimited',
  should_address_be_visible: 'Should the address be visible?',
  desc_address_visible: 'All app users will see your address',
  private: '(Private)',
  your_email: 'Your Email',
  price_includes_tax: 'Price includes tax',
  pay: 'Pay',
  accept: 'Accept',
  delete_photo: 'Delete Photo',
  which_music_do_you_like: 'Which music do you like?',
  i_am_new_here: 'Ich bin neu hier',
  remove: 'Remove',
  apply: 'Apply',
  apply_code_to: 'Apply code to',
  all_tickets: 'All tickets',
  select_tickets: 'Select Tickets',
  event: 'Event',
  drink: 'Drink',
  add_drinks_menu: 'Add Drinks Menu',
  edit_drinks_menu: 'Edit Drinks menu',
  drink_name: 'Drink Name',
  paid_online: 'Paid online',
  paid_by_card: 'EC/CreditCard',
  paid_by_cash: 'Paid by Cash',
  tip: 'Tip',
  sold_drinks: 'Sold Drinks',
  non_alcohol: 'Non-Alcohol',
  select_shot: 'Select a Shot',
  select_non_alcohol: 'Select a Non-Alcohol',
  tickets_for_free: 'TICKETS FOR FREE?',
  become_a_promoter: 'Become a Promoter now!',
  for_the_period: 'For the period',
  only_visible_to_me: 'Only visible to me and my employees',
  display_event_count_down: 'Do you want to display an event countdown on the website?',
  export: 'Export',
  export_buyers: 'Export Buyers',
  export_followers: 'Export Followers',
  few_tickets_available: 'Few tickets available',
  show_or_hide_organizer: "Show or hide organizer",
  import_drinks: 'Import Drinks',
  duplicate: 'Duplicate',
  select_drink_category: 'Select drink category',
  enter_new_event_info: 'Enter new event info',
  select_event: 'Select Event',
  employees: 'Employees',
  add_employee: 'Add Employee',
  edit_employee: 'Edit Employee',
  employee_name: 'Employee Name',
  link_event: 'Link Event',
  role: 'Role',
  in_use: 'In Use',
  handle_drink_request: 'Handle Drink Request',
  use_manual_tip: 'Use Manual Tip',
  status: 'Status',
  active: 'Active',
  inactive: 'Inactive',
  all: 'All',
  invoice: 'Invoice',
  credit_for_commission_services_see_customer_number: 'Credit for commission services see customer number',
  invoice_number: 'Invoice Number',
  payout: 'Payout',
  the_handling_fee_includes_tax: 'The handling fee includes 19% sales tax.',
  credit_made_in_few_days: 'The credit will be made to the specified account within the next few days.',
  credit_already_made: 'The credit in the amount of {0} was credited to the above-mentioned bank account.',
  payouts: 'Payouts',
  post_date: 'Post Date',
  paid: 'Paid',
  day: 'Day',
  days: 'Days',
  hour: 'Hour',
  hours: 'Hours',
  minute: 'Minute',
  minutes: 'Minutes',
  second: 'Second',
  seconds: 'Seconds',
  transaction_overview_online_payment: 'Transaction overview/Online payments',
  date: 'Date',
  accept_ticket: 'Accept',
  tag: 'Tag',
  set_new_tag: 'Set new Tag',
  join_now: 'Join now',
  multiple_days_ticket: 'Multiple Days Ticket',
  event_days: 'Days',
  information_about_organizer: 'Information about the organizer',

  // V2
  find_events: 'Find events',
  find: 'Find',
  top_events: 'TOP Events',
  discover_more_events: 'Discover more events',
  from: 'From',
  clear: 'Clear',
  select_all: 'Select all',
  clear_filters: 'Clear filters',
  apply_filters: 'Apply filters',
  show_in_map: 'Show in map',
  about_the_event: 'About the event',
  become_an_organizer_title: 'The Future of Ticketing for Event Organizers',
  become_an_organizer_desc: 'We believe that great events should be organized smoothly, professionally, and with maximum success. Our mission is to provide event organizers with a powerful, intuitive, and flexible ticketing platform that simplifies ticket sales, maximizes reach, and increases revenue.\nWith our modern ticket shop, we offer you a tailor-made solution that is precisely tailored to your needs as an event organizer. From small events to sold-out stadiums - our system is scalable, secure and user-friendly. Use innovative features such as personalized marketing tools, real-time analytics and flexible pricing to get the most out of your event.\nWith an eye on the future, we are increasingly relying on social media to spread your event virally and achieve maximum goals. Through intelligent connections to platforms such as Instagram, Facebook and TikTok, you can increase your reach exponentially and run targeted campaigns that reach your audience exactly where they are.',
  effortless_ticketing: 'Effortless Ticketing - Maximum Efficiency',
  effortless_ticketing_desc: 'Simplify your ticket sales for festivals, parties or sporting events with a user-friendly, time-saving solution. More revenue, less effort!',
  amplify_your_brand: 'Your brand in the spotlight',
  amplify_your_brand_desc: 'Use the power of social media: let your guests share their experiences and turn your event into a viral success.',
  boost_your_revenue: 'Increase profits - without compromise',
  boost_your_revenue_desc1: 'Increase your sales by up to',
  boost_your_revenue_desc2: 'through targeted sponsorship, smart sales strategies and exclusive partnerships.',
  create_your_business_account_now_for_free: 'Create your free business profile now.',
  create_your_business_account_now_for_free_desc: 'Don\'t hesitate! Start with eventbox and look forward to successful events.',
  get_started: 'Get started',
  all_in_one_for_your_event: 'The ultimate all-in-one solution for your event!',
  all_in_one_for_your_event_desc: 'With our all-in-one ticketing and cash register system, you have full control, anytime and anywhere.\nWhether on your desktop or mobile on your smartphone - as an event organizer, you can monitor and control all processes in real time.\nFrom ticket sales to on-site payments: you have all the important data at your fingertips.\nNo delays, no complications - everything runs smoothly, right in your hands.\nThis way, you always have your event under control and can ensure that everything runs efficiently and successfully - wherever you are.',
  community_building: 'Community Building',
  community_building_desc: 'Use powerful, free tools to convert ticket buyers into long-term fans and expand your reach, fostering lasting relationships and strengthening brand loyalty.',
  custom_pricing: 'Custom Pricing',
  custom_pricing_desc: 'Make your ticket prices flexible and targeted. With our solution, you have full control over the pricing structure so that you can adapt it optimally to your needs and target groups.',
  seamless_check_in: 'Seamless Check-In',
  seamless_check_in_desc: 'Offer your guests quick and efficient entry via QR code scanning. This reduces waiting times and ensures a smooth event experience.',
  real_time_dashboard: 'Real-Time Dashboard',
  real_time_dashboard_desc: 'Track all relevant key figures and sales figures in real time. Our dashboard enables you to receive precise and up-to-date information at any time and to react quickly to changes.',
  financial_control: 'Financial Control',
  financial_control_desc: 'Benefit from transparent and detailed reports on orders, refunds and check-ins. This way, you always have an overview of your financial transactions and can make informed decisions.',
  fast_payouts: 'Fast Payouts',
  fast_payouts_desc: 'Receive your earnings quickly and directly - the day after the event. With our fast payout solution, you don\'t have to wait long for your money.',
  customer_friendly_cash_system: 'Customer-friendly Cash Register System',
  customer_friendly_cash_system_desc: 'Benefit now from our innovative cash register system: your visitors can pay conveniently and securely on site via the app. Faster, easier and completely cashless - for an all-round positive event experience!',
  transparent_fee_individual_solution: 'Transparent Ticket Fees & Individual Solutions',
  transparent_fee_individual_solution_desc: 'Our ticket fees are less than 4%, so you benefit from fair and transparent conditions. We also offer tailor-made solutions and individual agreements that are perfectly tailored to the needs of your event and your requirements as an organizer.',
  get_it_now: 'Get it now!',
  download_the_app: 'Download the App',
  work_with_us: 'Work with us!',
  at_eventbox_you_grow_in_strong_team: 'At Eventbox you grow in a strong team',
  operations: 'Operations',
  operations_desc: 'Open positions in our operation team',
  marketing: 'Marketing',
  marketing_desc: 'Open positions in our marketing team',
  profile_picture: 'Profile picture',
  company_details: 'Company details',
  _incoming_events: '{0} incoming events',
  _past_events: '{0} past events',
  add_new_ticket: 'Add new Ticket',
  edit_ticket: 'Edit Ticket',
  add_new_extra: 'Add new Extra',
  edit_extra: 'Edit Extra',
  add_new_promo_code: 'Add new Promo Code',
  edit_promo_code: 'Edit Promo Code',
  tickets: 'Tickets',
  total_tickets: 'Total Tickets',
  tickets_available: 'Tickets available',
  check_in_available: 'Check in available',
  drag_and_drop_or_browse: 'Drag and drop or browse',
  cost: 'Cost',
  number: 'Amount',
  subtotal: 'Subtotal',
  search: 'Search',
  extra: 'Extra',

  // Desc
  desc_home_buy_ticket: 'We make it easy for you!\nBuy your TICKET for events in your city with one click!\nExperience and share your unforgettable moments on eventbox!',
  desc_organizer_bank_account: 'If you would like to sell tickets for our event via our platform, please enter your bank details here so that we can transfer the money to you. Otherwise, you do not have to specify a bank account.',
  desc_want_sell_tickets_for_your_event: 'Do you want to sell tickets for your event?',
  desc_for_channel: 'Be a part of our exclusive promotion team!\nYou can sell discounted tickets to your friends and in return get fantastic rewards like free tickets or a unique opportunity to experience a backstage tour.\nDon\'t miss the chance to be part of this exciting team.\n\nRegister here now and receive your Ticket for FREE!',
  desc_for_employee_duplication: 'Please update the corresponding employee name.\nYou must use a unique employee name.',
  desc_enter_email_exactly_required_for_next_login: 'Please enter your correct email address, it will be required for the next login.',
  desc_sent_temporary_password_to_email: 'We have sent the temporary login password to your email [{0}].\nPlease check your email inbox (including your spam folder if necessary) and log in to the eventbox app.',
  desc_login_app_with_username: 'Please log in to the eventbox app with your user name [{0}].',
  desc_login_app_with_email_or_username: 'Please log in to the eventbox app with your email address [{0}] or your user name [{1}].',
  desc_you_already_registered_with_email_temporary_password_sent_at_: 'You have already registered with this email [{0}].\nThe temporary login password was sent to your email on {1}.',
  desc_assigned_vip_ticket_unlock_all: 'The organizer assigned {0} VIP Ticket(s). Would you like to unlock all tickets?',
  desc_extra: 'Required an extra help for your event? We offer to you exclusive services and products for your best experience',

  // Alert
  alert_success: 'Success',
  alert_unknown_error: 'An unknown error occurred.',
  alert_unexpected_error: 'An unexpected error occurred.',
  alert_required_field: '{0} is required.',
  alert_please_wait: 'Please wait ...',
  alert_sign_in: 'Please sign in.',
  alert_sign_in_again: 'Please sign in again.',
  alert_incorrect_password: 'Incorrect password',
  alert_your_account_has_been_blocked: 'Your account has been blocked. Please contact to customer service.',
  alert_your_account_has_been_deleted: 'Your account has been deleted.',
  alert_this_account_has_been_deleted: 'This account has been deleted.',
  alert_this_event_has_been_deleted: 'This event has been deleted.',
  alert_email_already_exist: 'This email already exists.',
  alert_phone_number_already_exist: 'This phone number already exists.',
  alert_username_already_exist: 'This username already exists.',
  alert_email_not_exist: 'No user with this email address was found.',
  alert_phone_not_exist: 'No user with this phone was found.',
  alert_username_not_exist: 'No user with this username was found.',
  alert_closed_by_user: 'Closed by user.',
  alert_min_characters: 'Please enter at least {0} characters in the {1}.',
  alert_special_character_not_allowed: 'The special character is not allowed.',
  alert_passwords_not_match: 'Passwords does not match.',
  alert_emails_not_match: 'Emails does not match.',
  alert_invalid_email: 'Invalid email address.',
  alert_invalid_iban_number: 'Invalid IBAN number.',
  alert_failed_to_send_confirm_email: 'Failed to send verification email to {0}.',
  alert_incorrect_verification_code: 'Incorrect verification code.',
  alert_over_max_account_per_phone: 'You can register 5 accounts with one phone number in max.',
  alert_pending_withdraw: 'You have pending withdraw now.\nPlease request new withdraw after the pending one is completed.',
  alert_max_50_percent: 'You can only request Maximum 50 percent.',
  alert_over_balance: 'You can not withdraw greater than ',
  alert_no_ticket: 'There are only {0} tickets remaining.',
  alert_no_extra: 'There are only {0} extras remaining.',
  alert_you_can_only_buy_1_vip_ticket: 'You can only buy 1 VIP ticket.',
  alert_you_can_only_buy_max__tickets: 'You can only buy {0} tickets in maximum.',
  alert_you_can_only_buy_max__extras: 'You can only buy {0} extras in maximum.',
  alert_you_can_only_buy_max__tickets_with_promo_code: 'You can only buy {0} tickets in maximum with this Promo Code.',
  alert_sent_verification_code: 'We have sent the verification code to email that you have entered.\nPlease check your spam or junk box if you can not see it in your inbox.',
  alert_unapproved_organizer: 'Thank you for your registration at eventbox.\nOur authentication team will check your organizer request.\nThis can take some time due to the current situation.\nIn case that your registration as an organizer is not possible, you will be registered as a single user.\nIf you still think that you are an organizer, you can contact our support by writing an email to info@eventboxde.com',
  alert_select_photo: 'Please select a photo.',
  alert_select_start_date: 'Please select the start date.',
  alert_select_end_date: 'Please select the end date.',
  alert_end_date_must_be_greater_than_start_date: 'The end date must be greater than start date',
  alert_select_address: 'Please select the address.',
  alert_select_genre: 'Please select the genre.',
  alert_select_category: 'Please select the Category.',
  alert_only_select_2_categories_in_max: 'You can select 2 categories in max at once.',
  alert_success_buy_ticket: 'Thank you for shopping at eventbox!\nYou can find the ticket in the App.',
  alert_minimum_must_be_less_than_: 'Minimum must be less than or equal to {0}.',
  alert_maximum_must_be_less_than_: 'Maximum must be less than or equal to {0}.',
  alert_maximum_must_be_grater_minimum: 'Maximum must be greater than or equal to minimum.',
  alert_add_event_tickets: 'Please add category and price options.',
  alert_added_successfully: 'Added successfully.',
  alert_updated_successfully: 'Updated successfully.',
  alert_deleted_successfully: 'Deleted successfully.',
  alert_wait_for_ticket: 'Payment is pending now.\nPlease wait a few minutes and check your ticket again.\nIf you can\'t find the ticket under "My Tickets" in the profile, please contact to us by email: info@eventboxde.com',
  alert_correct_promo_code: '{0} applied. A {1}% discount is available.',
  alert_incorrect_promo_code: 'Sorry, we don\'t recognize this Promo Code.',
  alert_duplicate_promo_code: 'The code entered already exists. Please choose another one and try again.',
  alert_select_tickets_for_promo_code: 'Please select at least one ticket for this Promo Code.',
  alert_add_drinks_menu: 'Please add drinks menu.',
  alert_select_shot: 'Please select a Shot.',
  alert_select_non_alcohol: 'Please select a Non-Alcohol.',
  alert_assign_inventory_to_employees: 'You can assign your inventory to Bars/Employees.',
  alert_not_valid_event: 'Sorry, this event is not valid to import the drinks',
  alert_employee_name_exist: 'This employee name: {0} already exists.',
  alert_login_to_app_first: 'Please login to the App first.',
  alert_link_is_expired: 'The link is already expired. Please contact the organizer.',
  alert_link_is_already_activated: 'The link is already activated. It is not possible to access the guest list again.',
  alert_tag_is_expired: 'This tag is already expired.',
  alert_set_new_tag_instruction: 'When you set a new Tag, the current event and orders are automatically completed and the payout is requested.\nThe order will restart with new Tag and the stocks will remain unchanged.\nIf you click OK, this process can\'t be undone.',
  alert_request_payout_after_end_event: 'You can request the payout only after the event ends.',
  alert_information_about_organizer: '<b>Notice:</b>\neventbox is not itself the organizer of the event offered. The event is carried out by the organizer, who is also the issuer of the tickets. The eventbox has only taken on the task of selling the tickets as a commission agent for the organizer.\n\n<b>Organizer:</b>\n{0}, {1}',
  alert_select_event: 'Please select an Event.',
  alert_select_role: 'Please select the role.',

  // Confirm
  confirm_logout: 'Are you sure you want to log out now?',
  confirm_delete: 'Are you sure you want to delete?',
  confirm_want_sell_tickets_for_your_event: 'Do you want to sell tickets for your event?',
  confirm_organizer: 'You are an organizer, artist (Singer, DJ, producer, etc.), or gastronomic company (concert hall, cinema, theater, restaurant, club etc.)?\nShowcase your brand, sell tickets, share your own posts, get sales reports, and more.\nSign up now.',
  confirm_edit_card_info: 'You need to save your bank account information in your profile. You want to edit it?',
};